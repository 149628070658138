.login-offset{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;

}

.login-content-conta{
    margin-top: 20px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    padding: 40px 10px 10px;
    text-align: center;
    /*top: 55%;*/
    /*left: 50%;*/
    /*-ms-transform: translate(-50%, -50%);*/
    /*transform: translate(-50%, -50%);*/
    position: relative;
}

.login-btn{
    width: 135px;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 15px;
    font-size: 18px;
    color: black;
    background-color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.margin-label-login{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: black;
    font-size: 18px;
    padding: 15px -5px;
    width: 220px;
    margin: 7px;
}

@media only screen and (max-width: 440px) {


    .margin-label-login{
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: black;
        font-size: 18px;
        padding: 15px -5px;
        width: 150px;
        margin: 7px;
    }

    .login-btn{
        width: 100px;
        height: 35px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 30px;
        margin-left: 13px;
        margin-right: 13px;
        font-size: 18px;
        color: black;
        background-color: white;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 285px) {


    .margin-label-login{
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: black;
        font-size: 18px;
        padding: 15px -5px;
        width: 110px;
        margin: 7px;
    }

    .login-btn{
        width: 80px;
        height: 35px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 30px;
        margin-left: 13px;
        margin-right: 13px;
        font-size: 18px;
        color: black;
        background-color: white;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}