.conta-login{
    height: 600px;
    position: absolute;
    background-color: black;
    width: 90%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.no-wrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    text-align: center;
}

.no-wrap-data{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    padding-bottom: 20px;
    text-align: center;

}

.conta-title-batistei{
    color: black;
    text-align: center;
    padding-top: 120px;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;

}

.conta-line{
    height: 5px;
    border: 3px solid black;
    width: 50px;
    margin-top: 70px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

.select-category{
    font-family: 'Montserrat', sans-serif;
    color: black;
    padding: 5px 40px;
    text-align: center;
    margin-left: 22px;
}


.label-conta-batistei-Cafea-form{
    padding:10px 168px 5px 5px;
}

.label-conta-batistei-Cafea-Decofeinizata-form{
    padding:10px 41px 5px 5px;
}

.label-conta-batistei-Cafea-250g-form{
    padding:10px 117px 5px 5px;
}

.label-conta-batistei-Lapte-form{
    padding:10px 166px 5px 5px;
}

.label-conta-batistei-Lapte-Fara-Lactoza-form{
    padding:10px 48px 5px 5px;
}

.label-conta-batistei-Lapte-Alternativ-form{
    padding:10px 76px 5px 5px;
}

.label-conta-batistei-Banana-Bread-form{
    padding:10px 88px 5px 5px;
}

.label-conta-batistei-Pungi-Banana-Bread-form{
    padding:10px 28px 5px 5px;
}

.label-conta-batistei-Cookies-form{
    padding:10px 149px 5px 5px;
}

.label-conta-batistei-Pungi-Cookies-form{
    padding:10px 88.5px 5px 5px;
}

.label-conta-batistei-Role-Casa-form{
    padding:10px 129px 5px 5px;
}

.label-conta-batistei-Role-POS-form{
    padding:10px 130px 5px 5px;
}

.label-conta-batistei-Sandwiches-form{
    padding:10px 111px 5px 5px;
}

.label-conta-batistei-Inghetata-form{
    padding:10px 130px 5px 5px;
}

.label-conta-batistei-Gheata-form{
    padding:10px 153px 5px 5px;
}

.label-conta-batistei-Topping-Caramel-form{
    padding:10px 63px 5px 5px;
}

.label-conta-batistei-Topping-Ciocolata-form{
    padding:10px 56px 5px 5px;
}

.label-conta-batistei-Frisca-form{
    padding:10px 166px 5px 5px;
}

.label-conta-batistei-Ciocolata-Neagra-form{
    padding:10px 65px 5px 5px;
}

.label-conta-batistei-Ciocolata-Alba-form{
    padding:10px 90px 5px 5px;
}

.label-conta-batistei-Ciocolata-Lapte-form{
    padding:10px 79px 5px 5px;
}

.label-conta-batistei-Macha-Organic-form{
    padding:10px 79px 5px 5px;
}

.label-conta-batistei-Macha-Sweetened-form{
    padding:10px 52px 5px 5px;
}

.label-conta-batistei-Power-Chai-form{
    padding:10px 115px 5px 5px;
}

.label-conta-batistei-Spiced-Chai-form{
    padding:10px 110px 5px 5px;
}

.label-conta-batistei-Chichory-Latte-form{
    padding:10px 87px 5px 5px;
}

.label-conta-batistei-Paletine-form{
    padding:10px 145px 5px 5px;
}

.label-conta-batistei-Zahar-Alb-form{
    padding:10px 130px 5px 5px;
}

.label-conta-batistei-Zahar-Brun-form{
    padding:10px 115px 5px 5px;
}

.label-conta-batistei-Lavete-Micro-form{
    padding:10px 103px 5px 5px;
}

.label-conta-batistei-Lavete-Burete-form{
    padding:10px 92px 5px 5px;
}

.label-conta-batistei-Burete-Abraziv-form{
    padding:10px 87px 5px 5px;
}

.label-conta-batistei-Manusi-form{
    padding:10px 151px 5px 5px;
}

.label-conta-batistei-Bicarbonat-form{
    padding:10px 119px 5px 5px;
}

.label-conta-batistei-Otet-form{
    padding:10px 177px 5px 5px;
}

.label-conta-batistei-Detergent-Maini-form{
    padding:10px 70px 5px 5px;
}

.label-conta-batistei-Detergent-Podele-form{
    padding:10px 58px 5px 5px;
}

.label-conta-batistei-Spirt-form{
    padding:10px 175px 5px 5px;
}

.label-conta-batistei-Uleiuri-Esentiale-form{
    padding:10px 73px 5px 5px;
}

.label-conta-batistei-Detergent-Grupuri-form{
    padding:10px 51px 5px 5px;
}

.label-conta-batistei-Saci-120L-form{
    padding:10px 136px 5px 5px;
}

.label-conta-batistei-Saci-60L-form{
    padding:10px 140px 5px 5px;
}

.label-conta-batistei-Servetele-form{
    padding:10px 136px 5px 5px;
}

.label-conta-batistei-Bomboane-Cafea-form{
    padding:10px 61px 5px 5px;
}

.label-conta-batistei-Pahar-Espresso-4-Oz-form{
    padding:10px 31px 5px 5px;
}

.label-conta-batistei-Capac-Espresso-4-Oz-form{
    padding:10px 28px 5px 5px;
}

.label-conta-batistei-Pahar-Doppio-7-Oz-form{
    padding:10px 45px 5px 5px;
}

.label-conta-batistei-Capac-Doppio-7-Oz-form{
    padding:10px 42px 5px 5px;
}

.label-conta-batistei-Pahar-S-8-Oz-form{
    padding:10px 98px 5px 5px;
}

.label-conta-batistei-Capac-S-8-Oz-form{
    padding:10px 95px 5px 5px;
}

.label-conta-batistei-Pahar-M-12-Oz-form{
    padding:10px 87px 5px 5px;
}

.label-conta-batistei-Pahar-L-16-Oz-form{
    padding:10px 93px 5px 5px;
}

.label-conta-batistei-Pahar-XL-22-Oz-form{
    padding:10px 80px 5px 5px;
}

.label-conta-batistei-Capac-M-20-Oz-form{
    padding:10px 78px 5px 5px;
}

.label-conta-batistei-Pahar-Frappe-M-form{
    padding:10px 71px 5px 5px;
}

.label-conta-batistei-Pahar-Frappe-L-form{
    padding:10px 78px 5px 5px;
}

.label-conta-batistei-Capac-Frappe-form{
    padding:10px 92px 5px 5px;
}

.label-conta-batistei-Paie-Groase-form{
    padding:10px 111px 5px 5px;
}

.label-conta-batistei-Paie-Subtiri-form{
    padding:10px 114px 5px 5px;
}

.label-conta-batistei-Macarons-form{
    padding:10px 131px 5px 5px;
}

.label-conta-batistei-Creme-Brulee-form{
    padding:10px 92px 5px 5px;
}

.label-conta-batistei-Fistic-form{
    padding:10px 171px 5px 5px;
}

.label-conta-batistei-Macadamia-form{
    padding:10px 112px 5px 5px;
}

.label-conta-batistei-Hazelnut-form{
    padding:10px 136px 5px 5px;
}

.label-conta-batistei-Roasted-Special-form{
    padding:10px 75px 5px 5px;
}

.label-conta-batistei-Salted-Caramel-form{
    padding:10px 81px 5px 5px;
}

.label-conta-batistei-Crunch-My-Toffee-form{
    padding:10px 60px 5px 5px;
}

.label-conta-batistei-Irish-form{
    padding:10px 179px 5px 5px;
}

.label-conta-batistei-Tiramisu-form{
    padding:10px 141px 5px 5px;
}

.label-conta-batistei-Amaretto-form{
    padding:10px 132px 5px 5px;
}

.label-conta-batistei-Royal-Mint-form{
    padding:10px 122px 5px 5px;
}

.label-conta-batistei-Dulce-De-Leche-form{
    padding:10px 72px 5px 5px;
}

.label-conta-batistei-Chocolate-Cookie-form{
    padding:10px 61px 5px 5px;
}

.label-conta-batistei-Rom-form{
    padding:10px 173px 5px 5px;
}

.label-conta-batistei-Marshmallow-form{
    padding:10px 97px 5px 5px;
}

.label-conta-batistei-Pumpkin-Spice-form{
    padding:10px 80px 5px 5px;
}

.label-conta-batistei-Cinnamon-form{
    padding:10px 122px 5px 5px;
}

.label-conta-batistei-Mojito-form{
    padding:10px 161px 5px 5px;
}

.label-conta-batistei-No-Sugar-Vanilla-form{
    padding:10px 69px 5px 5px;
}

.label-conta-batistei-No-Sugar-Caramel-form{
    padding:10px 52px 5px 5px;
}

.label-conta-batistei-Vanilla-form{
    padding:10px 158px 5px 5px;
}

.label-conta-batistei-Cocos-form{
    padding:10px 164px 5px 5px;
}

.label-conta-batistei-Caramel-form{
    padding:10px 142px 5px 5px;
}

.label-conta-batistei-Caramel-25-cl-form{
    padding:10px 95px 5px 5px;
}

.label-conta-batistei-Salted-Caramel-25-cl-form{
    padding:10px 34px 5px 5px;
}

.label-conta-batistei-Vanilla-25-cl-form{
    padding:10px 112px 5px 5px;
}

.label-conta-batistei-Cocos-25-cl-form{
    padding:10px 117px 5px 5px;
}

.label-conta-batistei-Tiramisu-25-cl-form{
    padding:10px 94px 5px 5px;
}

.label-conta-batistei-Irish-25-cl-form{
    padding:10px 132px 5px 5px;
}

.label-conta-batistei-Hazelnut-25-cl-form{
    padding:10px 89px 5px 5px;
}

.label-conta-batistei-Fenti-Cherry-form{
    padding:10px 106px 5px 5px;
}

.label-conta-batistei-Fenti-Cola-form{
    padding:10px 126px 5px 5px;
}

.label-conta-batistei-Fenti-Rose-form{
    padding:10px 119px 5px 5px;
}

.label-conta-batistei-Mellow-Orange-form{
    padding:10px 81px 5px 5px;
}

.label-conta-batistei-Mellow-Mango-form{
    padding:10px 85px 5px 5px;
}

.label-conta-batistei-Mellow-Mere-form{
    padding:10px 102px 5px 5px;
}

.label-conta-batistei-Fritz-Cola-form{
    padding:10px 133px 5px 5px;
}

.label-conta-batistei-Fritz-Portocale-form{
    padding:10px 88px 5px 5px;
}

.label-conta-batistei-Jumex-Capsune-form{
    padding:10px 73px 5px 5px;
}

.label-conta-batistei-Jumex-Cocos-form{
    padding:10px 99px 5px 5px;
}

.label-conta-batistei-Jumex-Mango-form{
    padding:10px 88px 5px 5px;
}

.label-conta-batistei-Rubi-Mango-form{
    padding:10px 106px 5px 5px;
}

.label-conta-batistei-Rubi-Rodie-form{
    padding:10px 117px 5px 5px;
}

.label-conta-batistei-Rubi-Passion-form{
    padding:10px 101px 5px 5px;
}

.label-conta-batistei-Dr-Pepper-Regular-form{
    padding:10px 48px 5px 5px;
}

.label-conta-batistei-Dr-Pepper-Cherry-form{
    padding:10px 58px 5px 5px;
}

.label-conta-batistei-Ginger-Beer-form{
    padding:10px 108px 5px 5px;
}

.label-conta-batistei-Energizante-form{
    padding:10px 110px 5px 5px;
}

.label-conta-batistei-Figa-form{
    padding:10px 178px 5px 5px;
}

.label-conta-batistei-Apa-Tonica-form{
    padding:10px 119px 5px 5px;
}

.label-conta-batistei-Apa-Plata-form{
    padding:10px 130px 5px 5px;
}

.label-conta-batistei-Apa-Minerala-form{
    padding:10px 99px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-Breakfast-form{
    padding:10px 52px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-EarlGrey-form{
    padding:10px 60px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-Menta-form{
    padding:10px 77px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-Jasmin-form{
    padding:10px 71px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-Verde-form{
    padding:10px 83px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-Cham-form{
    padding:10px 79px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-Piersica-form{
    padding:10px 66px 5px 5px;
}

.label-conta-batistei-Ceai-Plic-Berries-form{
    padding:10px 73px 5px 5px;
}

.label-conta-batistei-Scortisoara-form{
    padding:10px 120px 5px 5px;
}

.label-conta-batistei-Nucsoara-form{
    padding:10px 133px 5px 5px;
}

.label-conta-batistei-Cocos-Condiment-form{
    padding:10px 56px 5px 5px;
}

.label-conta-batistei-Ghimbir-form{
    padding:10px 143px 5px 5px;
}

.label-conta-batistei-Cayenne-Pepper-form{
    padding:10px 68px 5px 5px;
}

.label-conta-batistei-Turmeric-form{
    padding:10px 137px 5px 5px;
}

.label-conta-batistei-Stevia-form{
    padding:10px 164px 5px 5px;
}

.label-conta-batistei-Zaharina-form{
    padding:10px 138px 5px 5px;
}

.label-conta-batistei-Cardamon-form{
    padding:10px 122px 5px 5px;
}

.label-conta-batistei-Hazelnut-Almonds-form{
    padding:10px 51px 5px 5px;
}

.label-conta-batistei-Hazelnut-Cashews-form{
    padding:10px 51px 5px 5px;
}

.label-conta-batistei-Almonds-Tahini-form{
    padding:10px 78px 5px 5px;
}

.label-conta-batistei-Almonds-Aloe-form{
    padding:10px 92px 5px 5px;
}

.label-conta-batistei-Almonds-Plums-form{
    padding:10px 73px 5px 5px;
}

.label-conta-batistei-Almonds-Cocoa-form{
    padding:10px 76px 5px 5px;
}

.label-conta-batistei-Almonds-Figs-form{
    padding:10px 94px 5px 5px;
}

.label-conta-batistei-FJ-Cherry-Yogurt-1038-form{
    padding:10px 32px 5px 5px;
    font-size: 17px;
}

.label-conta-batistei-FJ-Tripple-Chocolate-1041-form{
    padding:10px 5px 5px 5px;
    font-size: 17px;
}

.label-conta-batistei-FJ-Strawberry-Cheese-1036-form{
    padding:10px 5px 5px 5px;
    font-size: 16px;
}

.label-conta-batistei-FJ-Blueberries-1033-form{
    padding:10px 56px 5px 5px;
    font-size: 17px;
}

.label-conta-batistei-FJ-Walnuts-1032-form{
    padding:10px 82px 5px 5px;
    font-size: 17px;
}

.label-conta-batistei-FJ-Banana-1040-form{
    padding:10px 82px 5px 5px;
    font-size: 17px;
}

.label-conta-batistei-FJ-Chocolate-Chip-1034-form{
    padding:10px 19px 5px 5px;
    font-size: 17px;
}

.label-conta-batistei-FJ-Cookies-Cream-1039-form{
    padding:10px 22px 5px 5px;
    font-size: 17px;
}

.label-conta-batistei-Fj-Choco-Coconut-1035-form{
    padding:10px 12px 5px 5px;
}

.label-conta-batistei-Keto-Cookies-Cream-BW-2025-form{
    padding:10px 145px 5px 5px;
}

.label-conta-batistei-Activ-Double-Chocolate-BW-2024-form{
    padding:10px 142px 5px 5px;
}

.label-conta-batistei-Boost-Chocolate-Chip-BW-2022-form{
    padding:10px 148px 5px 5px;
}

.label-conta-batistei-Boost-Double-Chocolate-Blueberry-BW-2023-form{
    padding:10px 20px 5px -0px;

}

.label-conta-batistei-Slim-Cookies-Cream-BW-2026-form{
    padding:10px 156px 5px 5px;
}

.label-conta-batistei-Slim-Double-Chocolate-Chip-BW-2027-form{
    padding:10px 120px 5px 5px;
}

.label-conta-batistei-Deluxe-Chocolate-Chip-BW-2028-form{
    padding:10px 160px 5px 5px;
}

.label-conta-batistei-Deluxe-Cookies-Cream-BW-2029-form{
    padding:10px 160px 5px 5px;
}

.conta-content{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding: 40px 5px 5px;
    text-align: center;
    /*top: 55%;*/
    /*left: 50%;*/
    /*-ms-transform: translate(-50%, -50%);*/
    /*transform: translate(-50%, -50%);*/
    /*position: absolute;*/
}

.conta-content-batistei{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding: 0px 5px 5px;
    text-align: center;
    /*top: 100%;*/
    /*left: 50%;*/
    /*-ms-transform: translate(-50%, -80%);*/
    /*transform: translate(-50%, -80%);*/
    /*position: absolute;*/
}


.label-conta-batistei-data{
    padding:10px 50px 5px 5px;
    margin-top: 115px;
}

.label-conta-batistei-cafea{
    padding:10px 66px 5px 5px;
}

.label-conta-batistei-cafenea{
    padding:10px 30px 5px 5px;
    margin-left: 25px;
}

.label-conta-batistei-lapte{
    padding:10px 64px 5px 5px;
}

.label-conta-batistei-alt{
    padding:10px 30px 5px 5px;
}

.label-conta-batistei-cash{
    padding:10px 72px 5px 5px;
}

.label-conta-batistei-card{
    padding:10px 73px 5px 5px;
}

.label-conta-batistei-bonuri{
    padding:10px 57px 5px 5px;
}

/**/

label-conta-batistei-cafeaKg-form{
    color: white;
}




/**/

.margin-label-submit-conta{
    margin: 10px 5px 5px 11px;
    padding: 5px 84px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    background-color: white;
    border-radius: 5px;
}

.margin-label-submit-conta-sandwich{
    padding: 5px 0px;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    background-color: green;
    color: white;
    border-radius: 5px;
    border: solid 2px green;
    margin-bottom: 15px;

}

.custom-textarea {
    width: 90%;
    height: 300px;
    padding: 8px;
    resize: vertical;
    margin-left: 25px;
    font-weight: 4400;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}



@media only screen and (max-width: 700px) {
    .conta-login {
        height: 550px;
    }

    .conta-title-batistei{
        color: #000000;
        text-align: center;
        padding-top: 70px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 28px;
        padding-bottom: 100px;
    }



}





    .conta-content-batistei{
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 18px;
        padding: 40px 2px 2px;
        text-align: center;
        /*top: 180%;*/
        /*left: 50%;*/
        /*-ms-transform: translate(-50%, -170%);*/
        /*transform: translate(-50%, -170%);*/
        /*position: absolute;*/
    }


    .margin-label-conta{
        margin-top: 15px;
        padding: 4px 5px;
        width: 160px;
    }

.margin-label-conta-form{
    height: 25px;
    width: 120px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
}

.margin-label-conta-form1{
    height: 25px;
    width: 60px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
}

    .margin-label-conta0{
        margin: 5px;
        padding: 3px 20px;
    }

    .margin-label-submit-conta {
        margin: 10px 5px 5px 11px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 18px;
        background-color: white;
        border-radius: 5px;
        color: black;
        padding: 5px 0px;
        width: 300px;
    }



    input[type="date"] {
        /* Add your iOS-specific styles here */
        padding: 0px 55px 5px 5px;
        color: black;
    }

@media screen and (-webkit-min-device-pixel-ratio: 2) {
    /* Target iOS devices */
    input[type="date"] {
        /* Add your iOS-specific styles here */
        padding: 0px 55px 5px 5px;
        color: black;
    }
}

@media screen and (-webkit-device-pixel-ratio: 2) and (max-width: 375px)  {
    /* Target iOS devices */
    input[type="date"] {
        /* Add your iOS-specific styles here */
        padding: 0px 55px 5px 5px;
        color: black;
    }
}

@media only screen and (max-width: 370px) {
    .margin-label-conta{
        margin-top: 10px;
        width: 120px;
    }


    .margin-label-submit-conta{
        margin: 10px 5px 5px 11px;
        padding: 5px 0px;
        width: 95%;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 15px;
        background-color: white;
        border-radius: 5px;
    }

    .margin-label-conta-form{
        height: 25px;
        width: 80px;
        margin-bottom: 10px;
        margin-right: 10px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        text-align: center;
    }

    .label-conta-batistei-alt{
        padding:10px 26px 5px 5px;
    }
}


