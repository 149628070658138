.title-retetar{
    padding-top: 100px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.linie-retetar{
   border: solid black 2px;
    width: 40px;

}

.item-name {
    white-space: pre-line; /* Preserve line breaks */
}

.item-name::before {
    content: '\A';
    white-space: pre;
}

.item-name::after {
    content: '\A';
    white-space: pre;
}

.form-preturi{
    border: 2px solid black;
    margin-right: 15px;
    margin-left: 15px;
}

.item-total{
    font-size: 18px;
    color: #ffffff;
    background-color: black;
    padding: 20px;
    margin-top: 20px;
    margin-left: 30%;
    margin-right: 30%;
}

.total-sum{
    border: 2px solid black;
    margin-top: 20px;
    margin-left: 30%;
    margin-right: 30%;
    height: 330px;
}

.label-retetar-cafea{
    padding:10px 70px 10px 10px;
    width: 100px;
}

.label-retetar-lapte{
    padding:10px 70px 10px 10px;
    width: 100px;
}

.label-retetar-sirop{
    padding:10px 75px 10px 10px;
    width: 100px;
}

.label-retetar-inghetata{
    padding:10px 38px 10px 10px;
    width: 100px;
}

.label-retetar-gheata{
    padding:10px 58px 10px 10px;
    width: 100px;
}

.label-retetar-frisca{
    padding:10px 71px 10px 10px;
    width: 100px;
}

.label-retetar-topping{
    padding:10px 52px 10px 10px;
    width: 100px;
}

.label-retetar-apaTonica{
    padding:10px 32px 10px 10px;
    width: 100px;
}

.label-retetar-pahar4Oz{
    padding:10px 40px 10px 10px;
    width: 100px;
}

.label-retetar-capac4Oz{
    padding:10px 38px 10px 10px;
    width: 100px;
}

.label-retetar-pahar7Oz{
    padding:10px 44px 10px 10px;
    width: 100px;
}

.label-retetar-capac7Oz{
    padding:10px 42px 10px 10px;
    width: 100px;
}

.label-retetar-pahar8Oz{
    padding:10px 45px 10px 10px;
    width: 100px;
}

.label-retetar-capac8Oz{
    padding:10px 43px 10px 10px;
    width: 100px;
}

.label-retetar-pahar12Oz{
    padding:10px 42px 10px 10px;
    width: 100px;
}

.label-retetar-capac12Oz{
    padding:10px 30px 10px 10px;
    width: 100px;
}

.label-retetar-pahar16Oz{
    padding:10px 43px 10px 10px;
    width: 100px;
}

.label-retetar-pahar22Oz{
    padding:10px 41px 10px 10px;
    width: 100px;
}

.label-retetar-frappeM{
    padding:10px 22px 10px 10px;
    width: 100px;
}

.label-retetar-frappeL{
    padding:10px 30px 10px 10px;
    width: 100px;
}

.label-retetar-capacFrappeML{
    padding:10px 25px 10px 10px;
    width: 100px;
}

.label-retetar-logo{
    padding:10px 93px 10px 10px;
    width: 100px;
}

.label-retetar-macha{
    padding:10px 80px 10px 10px;
    width: 100px;
}

.label-retetar-sweetMacha{
    padding:10px 28px 10px 10px;
    width: 100px;
}

.label-retetar-chai{
    padding:10px 98px 10px 10px;
    width: 100px;
}

.label-retetar-chicory{
    padding:10px 75px 10px 10px;
    width: 100px;
}

.label-retetar-turmeric{
    padding:10px 65px 10px 10px;
    width: 100px;
}

.label-retetar-ceai{
    padding:10px 103px 10px 10px;
    width: 100px;
}

.label-retetar-ciocoLapte{
    padding:10px 45px 10px 10px;
    width: 100px;
}

.label-retetar-ciocoNeagra{
    padding:10px 34px 10px 10px;
    width: 100px;
}

.label-retetar-ciocoAlba{
    padding:10px 55px 10px 10px;
    width: 100px;
}

.label-retetar-zaharAlb{
    padding:10px 63px 10px 10px;
    width: 100px;
}

.label-retetar-zaharBrun{
    padding:10px 50px 10px 10px;
    width: 100px;
}

.label-retetar-paletina{
    padding:10px 75px 10px 10px;
    width: 100px;
}

.label-retetar-bomboana{
    padding:10px 53px 10px 10px;
    width: 100px;
}

.label-retetar-necuantificabile{
    padding:10px 65px 10px 10px;
    width: 100px;
}

.label-retetar-paie{
    padding:10px 109px 10px 10px;
    width: 100px;
}

.margin-label-retetar{
    width: 100px;
    height: 25px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.btn-retetar{
    padding: 8px 5px;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 1400;
    margin: 5px;
}

.drop-retetar{
    padding: 8px 5px;
    margin: 5px;
    font-family: 'Montserrat', sans-serif;
}

.drop-retetar-number{
    width: 60px;
    padding: 8px 5px;
    margin: 5px;
    font-family: 'Montserrat', sans-serif;
}

.data-preturi{
    padding: 5px 20px ;
}

.tva-container{
    padding: 20px;
}

.tva-btn{
    width: 25px;
    height: 25px;
    transform: scale(1.5);
    cursor: pointer;
    margin-left: 20px;
}

.tva-btn-label {
    font-size: 18px;
    margin-left: 10px;
}

.tva-text{
    font-size: 25px;
}