.title-nutr{
    text-align: center;
    padding-top: 80px;
    font-family: 'Montserrat', sans-serif;
}

.dropdown-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;

}

.dropdown-item {
    margin-bottom: 0.5rem;
}

.dropdown-toggle {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}

.cat-nut{
    padding: 8px;
    font-family: 'Montserrat', sans-serif;
    margin-left: 0.7rem;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
}

.additional-info {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 4px;
    max-width: 1200px;
    max-height: 300px; /* Set a maximum height for the dropdown content */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    font-family: 'Montserrat', sans-serif;
}


.categorie-meniu{
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    margin-top: 50px;
    margin-left: 1rem;
}

.ingredient{
    display: inline;

}

.gramaj{

}



