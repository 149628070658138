
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 700;
}



/* Add styles for the fading animation */
.website-content {
  opacity: 0; /* Initially set the opacity to 0 */
  animation: fadeIn 1s ease-in-out forwards;
  /* Animation properties: fadeIn [duration] [timing-function] [delay] [fill-mode] */
}

@keyframes fadeIn {
  to {
    opacity: 1; /* At the end of the animation, set the opacity to 1 */
  }
}


