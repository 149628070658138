
.grid-container .grid-container-franciza{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}


.grid-item {
    background-color: #ffffff;
    padding: 50px;
    text-align: center;
    border: 1px solid black;
    /*border-top: 1px solid black;*/
    font-family: 'Montserrat', sans-serif;
    margin: 10px 20px;
}

.grid-item-2 {
    background-color: #000000;
    padding: 58px;
    text-align: center;
    border: 1px solid #ffffff;
    /*border-top: 1px solid black;*/
    font-family: 'Montserrat', sans-serif;
    margin: 10px 20px;
    height: 370px;
    color: white;
}

.img-franciza, .img-franciza1, .img-franciza2{
    height: 35px;
    margin-top: 9px;
}

.img-franciza{
    margin-left: 10px;
}

.img-franciza1{
    margin-left: 15px;
}

.img-franciza2{
    margin-left: 10px;
}

.dec-franciza{
    width: 80px;
    border: 1px solid black;
    height: 2px;
    margin-top: -10px;
}


.grid-item-3 {
    padding: 25px;
    text-align: center;
    border: 1px solid black;
    font-family: 'Montserrat', sans-serif;
    height: 600px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 5%;
    margin-right: 5%;
    color: #000000;
    border-radius: 8px;
}

.franciza-flex{
    display: flex;
}

.button-container {
    display: flex;
    align-items: center;
    background-color: white;
}

.btn-franciza{
    background-color: white;
    text-decoration: none;
    border: none;
    font-family: 'Montserrat', sans-serif;
    max-width: 268px;
    height: 36px;
    text-align: center;
    font-size: 20px;
}

.req-franciza{
    text-align: left;
    padding-top: 20px;
    font-size: 22px;
}

.btn-desc{
    font-size: 19px;
    color: white;
    padding-top: 8px;
}

.btn-franciza-choice{
    max-width: 290px;
    background-color: black;
    height: 40px;
    border-radius: 5px;
    margin-top: 70px;
    text-align: center;
    margin-left: 0%;
    margin-right: 0%;
}

.desc-text-franciza{
    text-align: left;
    font-size: 20px;
    margin-top: 20px;
}


option, select{
    font-family: 'Montserrat', sans-serif;
}



.to-form{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    margin: 20px;
}

.separator-line {
    width: 30px;
    margin: 20px auto;
    border: none;
    border-top: 1.5px solid #000000;
    height: 1px;
    padding-bottom: 20px;
}

.font-roasted{
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin: 20px;
    margin-top: 0px;
    color: white;
    font-size: 22px;
    padding: 0px 20px;
    padding-bottom: 10px;
}

.number-gird{
    padding-top: 80px;
    font-size: 40px;
}

.form-headline{
    padding-top: 50px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding-bottom: 40px;
}

.input-nume{
    height: 30px;

}

.full-input{
    width: 100%;
    height: 50px;
}

.form-franciza {
    display: flex;
    flex-direction: column; /* Vertical layout for form groups */
    font-family: 'Montserrat', sans-serif;
    background-color: black;
    color: white;
    margin-left: 5%;
    margin-right: 5%;
    max-width: 500px;
}

.form-group {
    display: flex;
    flex-direction: row; /* Horizontal layout for label-input pairs */
    justify-content: space-between; /* Maximize space between label and input */
    align-items: center; /* Center items vertically within each pair */
    margin-bottom: 10px; /* Adjust spacing between pairs */
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
    font-size: 24px;
}

.label-group {
    flex-direction: column; /* Vertical layout for label-input pairs in this group */
    margin-left: 5%;
    margin-right: 5%;
    padding: 5px;
}

label {
    font-weight: bold; /* Optionally style the label */
}


.text-gird{
    padding-top: 10px;
    font-size:24px;
}

.text-gird-franciza{
    font-size:  24px;
    text-align: left;
    margin-top: 10px;
    width: 90%;
    height: 60px;
}

.title-page {
    padding-top: 150px;
    text-align: center;
    font-size: 35px;
    font-family: 'Montserrat', sans-serif;
}

.grid-container-franciza{
    border: 2px solid black;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
    gap: 10px; /* Adjust the gap between columns as needed */
}


@media (max-width: 768px) {
    .grid-container-franciza {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1080px) and (min-width: 768px) {
    .grid-container-franciza {
        grid-template-columns: repeat(2, 1fr);
    }
}


.franciza-cont{
    height: 800px;
    border: 2px solid black;
    margin-left: 5%;
    margin-right: 5%;
    padding: 5px;
}
