.contact{
    background-color: black;
    height: 350px;
    justify-content: center;
}

.contact-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
    text-align: center;
    font-size: 28px;
    padding-top: 40px;
    margin-top: 40px;
}

.contact-decorator{
    background-color: white;
    margin-top: 8px;
    width: 40px;
    height: 2px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.contact-email{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
    text-align: center;
    font-size: 18px;
    padding-top: 20px;
    text-decoration: none;
}

.contact-info{
    padding: 5px;
    margin-right: 30px;
    margin-left: 30px;
}

a{
    text-decoration: none;
    color: white;
}

.socials{
    display: flex;
    justify-content: center;
}

.socials-container{
    background-color: white;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 20px 20px;
    margin-top: 30px;
}

.credits{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
    text-align: center;
    font-size: 15px;
    padding-top: 3%;
    text-decoration: none;
    height: 100px;
    background-color: black;
}

.socials-icon{
    height: 50px;
    width: 50px;
    border-radius: 100%;
    object-fit: contain;
}

@media only screen and (min-width: 700px) {

    .contact{
        margin-top: 150px;
    }

}