.option-btn{
    text-align: center;
}




.stats{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.chart-row{
    text-align: center;
    padding-top: 100px;
    width: 100%;
}

.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown button */
.dropdown-button {
    padding: 4px;
    margin: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: black;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /*-webkit-appearance: none; !* Reset default styling on iOS devices *!*/
}

.center-formulas{
    position: relative;
    height: 200px;
}

.pdf-down{
    background-color: white;
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
}

.all-orders{
    border: solid black 2px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 20px;
}

.center-info{
    background-color: black;
    color: white;
    width: 200px;
    text-align: center;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}

.center-formulas-formulas{
    position: relative;
    height: 200px;
}

.center-info-formulas{
    background-color: black;
    color: white;
    width: 300px;
    text-align: center;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}

.center-formulas-formulas{
    position: relative;
    height: 200px;
}

.center-first{
    background-color: black;
    color: white;
    width: 250px;
    text-align: center;
    top: 380px;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    height: 240px;

}


@media only screen and (max-width: 700px) {
    .center-info-first{
        margin-top: 20px;
        height: 80px;
    }

    .input-year, .input-year-date{
        margin-bottom: 50px;
    }
}

.submit-btn{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    background-color: white;
    color: black;
    padding: 5px 20px;
    margin-left: 10px;
}

.comanda-id{
    background-color: black;
    color: white;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    white-space: pre;
}

.btn-del{
    padding: 3px;
    width: 100px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.urgent-text {
    color: red;
    font-weight: bold;
}


.input-comanda{
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    width: 100px;
}

.input-year,.input-year1, .input-year-date{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    background-color: white;
    color: black;
    border: 2px solid black;
}

.input-year{
    padding: 5px 20px;
}

.input-year1{
    padding: 5px 20px;
    margin-left: 5px;
}


.center-info-formulas{
    background-color: black;
    color: white;
    width: 300px;
    text-align: center;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}


/* Dropdown content */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Dropdown items */
.dropdown-item {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

/* Show the dropdown content when the button is clicked */
.dropdown:hover .dropdown-content {
    display: block;
}