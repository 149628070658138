

.roasted-logo-div{
 height: 1200px;
    transition: opacity 1s ease;
}

.menu-3, .menu-2{
    height: 150px;
    justify-content: center;
    display: flex;
}

.menu-3{
    padding-top: 50px;
}

.button-menu-header{
    height: 25px;
    padding: 28px 60px;
    margin: 0 20px;
    background-color: white;
    border: black 2px solid;
    cursor: pointer;
}

.button-menu-header-menu{
    height: 25px;
    padding: 28px 60px;
    margin: 0 20px;
    background-color: white;
    border: black 2px solid;
    cursor: pointer;
}

.title-menu-header{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-top: -12px;
    padding: 0 0px ;
    color: black;
}

.title-menu-header-menu{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
   margin-bottom: 50px;
    color: black;
}

.line-sep{
    height: 2px;
    width: 100%;
    background-color: black;
}

.space-sep{
    height: 200px;
    border-bottom: 2px solid black;
    border-top: 2px solid black
}


.menu-button-text{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: white;
    margin-top: -2px;
}

.menu-decorator{
    margin-top: 5px;
    width: 6%;
    justify-content: center;
    background-color: white;
    height: 2px;
}

@media only screen and (max-width: 999px) {
    .menu-3, .menu-2 {
        display: none;
    }

    .menu-btn{
        background-color: black;
        display: block;
        justify-content: center;
        margin: 100px 50px;
        padding: 60px 0px;
    }

    .menu-decorator {
        margin-top: 10px;
        width: 15%;
    }


    .menu-button-text{
        font-size: 30px;
        color: white;
        margin-top: -2px;
    }

    .space-sep{
        display: none;
    }

    .roasted-logo-img{
        height: 800px;
    }

    .roasted-logo{
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 250px;
    }

    .roasted-logo-div{
        height: 1200px;
    }

}

@media only screen and (min-width: 1000px) {

    .menu-btn {
        margin: 150px 50px;
        padding: 70px 0px;
        background-color: black;
    }

    .space-sep{
        display: none;
    }

    .roasted-logo{
        padding-top: 10rem;
        object-fit: contain;
        justify-content: center;
        display: flex;
        height: 25rem;
    }

}